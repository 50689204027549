import gsap from 'gsap'
import Swiper, {Lazy, Mousewheel} from 'swiper'
import getScroller from '../services/scroller'
import {$, $BODY, $PAGE_OVERLAYS, Component, ComponentFactory, isLG, Query} from '../util'

class ProjectModalComponent extends Component {
	$modal: Query

	$gallery: Query

	$target?: Query

	$clone?: Query

	$close?: Query

	isActive = false

	isLoading = false

	direction: 'horizontal' | 'vertical' = isLG() ? 'vertical' : 'horizontal'

	swiper: Swiper

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$modal = $(this.el)
		this.$gallery = this.$modal.query('.swiper')
		this.$close = this.$modal.query('.close')

		this._initSwiper()

		this.$modal.on('toggleModal', (e: CustomEvent<any>) => {
			if (e.detail.target) {
				this.$target = $(e.detail.target).query('.embed')
				this._toggleModal()
			}
		})

		this.$modal.on('openModal', (e: CustomEvent<any>) => {
			if (e.detail.target) {
				this.$target = $(e.detail.target).query('.embed')
				this._openModal()
			}
		})

		this.$modal.on('closeModal', (e: CustomEvent<any>) => {
			if (e.detail.target) {
				this.$target = $(e.detail.target).query('.embed')
				this._closeModal()
			}
		})

		this.$close.on('click', (e: MouseEvent) => {
			e.preventDefault()
			this._closeModal()
		})
	}

	onKeydown(e: KeyboardEvent): void {
		if (!this.isActive) return

		if (e.key === 'Escape') {
			this._closeModal()
		}
	}

	onResize() {
		if (isLG() && this.direction === 'horizontal') {
			this._initSwiper()
			return
		}

		if (!isLG() && this.direction === 'vertical') {
			this._initSwiper()
			return
		}
	}

	onDestroy() {
		this.swiper?.destroy()
		this.swiper = undefined
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _toggleModal() {
		if (this.isActive) {
			await this._closeModal()
		} else {
			await this._openModal()
		}
	}

	private async _openModal() {
		if (this.isLoading) return

		getScroller().paused(true)

		this.isLoading = true
		this.$modal.emit('modalWillOpen')
		this.swiper.disable()

		const tl = this._timeline()
		await tl.play()

		this.isActive = true
		this.isLoading = false
		this.$modal.emit('modalDidOpen')
		this.swiper.enable()
	}

	private async _closeModal() {
		if (this.isLoading) return

		this.isLoading = true
		this.$modal.emit('modalWillClose')
		this.swiper.disable()

		await this._resetSwiper()

		const tl = this._timeline(true)
		await tl.reverse(0)

		this.isActive = false
		this.isLoading = false
		this.$modal.emit('modalDidClose')
		this.swiper.enable()

		getScroller().paused(false)
	}

	private _timeline(reversed = false) {
		this._createClone()

		const targetRect = this.$target.rect()
		const galleryRect = this._getGalleryRect()

		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				$BODY.addClass('modal-active')
				this.$modal.addClass('project-modal--active')
			},
			onReverseComplete: () => {
				$BODY.removeClass('modal-active')
				this.$modal.removeClass('project-modal--active')
				this._destroyClone()
			},
		})

		tl.set(this.$gallery, {opacity: 0})
		tl.set(this.$gallery, {opacity: 0})

		tl.addLabel('start')

		tl.fromTo(
			this.$modal,
			{
				display: 'none',
				autoAlpha: 0,
			},
			{
				duration: 0.4,
				display: 'block',
				autoAlpha: 1,
			},
			'start',
		)

		tl.fromTo(
			this.$clone,
			{
				top: `${targetRect.top}px`,
				left: `${targetRect.left}px`,
				width: `${targetRect.width}px`,
				height: `${targetRect.height}px`,
			},
			{
				duration: 0.4,
				top: `${galleryRect.top}px`,
				left: `${galleryRect.left}px`,
				width: `${galleryRect.width}px`,
				height: `${galleryRect.height}px`,
			},
			'start',
		)

		tl.addLabel('modal-open')

		tl.fromTo(
			this.$gallery,
			{
				opacity: 0,
			},
			{
				duration: 0.4,
				opacity: 1,
			},
			'modal-open',
		)

		tl.add(() => {
			gsap.set(this.$clone, {
				autoAlpha: reversed ? 1 : 0,
			})
		})

		return tl.play()
	}

	private _createClone() {
		if (this.$clone) return

		this.$clone = this.$target.clone()
		const rect = this.$target.rect()

		this.$clone.css({
			'position': 'fixed',
			'top': `${rect.top}px`,
			'left': `${rect.left}px`,
			'width': `${rect.width}px`,
			'height': `${rect.height}px`,
			'pointer-events': 'none',
		})

		this.$clone.forEach((el) => {
			$PAGE_OVERLAYS.append('beforeend', el)
		})
	}

	private _destroyClone() {
		if (!this.$clone) return

		this.$clone.remove()
		this.$clone = undefined
	}

	private _getGalleryRect() {
		const rect = {
			top: 0,
			left: 0,
			width: 0,
			height: 0,
		}

		this.$modal.setStyle('display', 'block')

		const galleryRect = this.$gallery.rect()
		const embedRect = this.$gallery.query('.embed').rect()

		rect.top = galleryRect.top
		rect.left = isLG() ? galleryRect.left : galleryRect.left + 20
		rect.width = embedRect.width
		rect.height = embedRect.height

		this.$modal.removeStyle('display')

		return rect
	}

	private _initSwiper() {
		this.swiper?.destroy()

		this.direction = isLG() ? 'vertical' : 'horizontal'

		this.swiper = new Swiper(this.$gallery.el, {
			centeredSlides: true,
			direction: this.direction,
			initialSlide: 0,
			lazy: {
				checkInView: true,
				enabled: true,
				loadPrevNext: true,
				loadPrevNextAmount: 4,
			},
			loop: true,
			modules: [Lazy, Mousewheel],
			mousewheel: isLG(),
			slidesPerView: 'auto',
			spaceBetween: isLG() ? 70 : 20,
		})
	}

	private _resetSwiper() {
		return new Promise((resolve, reject) => {
			const activeSlide = this.swiper.slides[this.swiper.activeIndex]

			if (!activeSlide) {
				reject('Active slide could not be found')
			}

			const $activeSlide = $(activeSlide as HTMLElement)

			if ($activeSlide.getData('swiper-slide-index') === '0') {
				resolve(true)
			}

			this.swiper.on('transitionEnd', () => {
				this.swiper.off('transitionEnd')
				resolve(true)
			})

			this.swiper.enable()

			this.swiper.slideToLoop(0, 500, true)
		})
	}
}

export default new ComponentFactory({
	selector: '.project-modal',
	component: ProjectModalComponent,
})
