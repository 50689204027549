import Swiper, {FreeMode} from 'swiper'
import {$, Component, ComponentFactory, Query} from '../util'

class HomeProjects extends Component {
	$container: Query

	swiper?: Swiper

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)

		this.swiper = new Swiper(this.el, {
			freeMode: true,
			modules: [FreeMode],
			spaceBetween: 30,
			slidesPerView: 'auto',
		})
	}

	onDestroy() {
		this.swiper?.destroy()
		this.swiper = undefined
	}
}

export default new ComponentFactory({
	selector: '.projects-slider',
	component: HomeProjects,
})
