import {$, Component, ComponentFactory, Query} from '../util'

class ProjectModalToggleComponent extends Component {
	$toggle: Query

	$modal: Query

	// ----------------------------------------
	// Computed Properties
	// ----------------------------------------

	get isActive() {
		return this.$toggle.hasClass('project-card--active')
	}

	set isActive(value) {
		if (value) {
			this.$toggle.addClass('project-card--active')
		} else {
			this.$toggle.removeClass('project-card--active')
		}
	}

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$toggle = $(this.el)
		this.$modal = $(this.$toggle.getData('toggle-project'))

		if (!this.$modal) {
			throw new Error('No modal was found')
		}

		this.$toggle.on('click', (e) => {
			e.preventDefault()

			this.$modal.emit('toggleModal', {
				target: this.$toggle.el,
			})
		})

		this.$modal.on('modalWillOpen', () => {
			this.isActive = true
		})

		this.$modal.on('modalDidClose', () => {
			this.isActive = false
		})
	}
}

export default new ComponentFactory({
	selector: '[data-toggle-project]',
	component: ProjectModalToggleComponent,
})
