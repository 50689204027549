import gsap from 'gsap'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollEvent} from '../types'
import {$PAGE_SCROLLER, $PAGE_WRAPPER} from '../util'

gsap.registerPlugin(ScrollSmoother, ScrollToPlugin, ScrollTrigger)

export default function getScroller() {
	return ScrollSmoother.get()
}

function createScroller() {
	ScrollSmoother.create({
		wrapper: $PAGE_WRAPPER.el,
		content: $PAGE_SCROLLER.el,
		effects: true,
		smooth: 1,
		smoothTouch: 0.01,
		normalizeScroll: false,
		ignoreMobileResize: false,
		onUpdate: (instance) => {
			window.dispatchEvent(
				new CustomEvent<ScrollEvent>('onScroll', {
					detail: {
						top: instance.scrollTop(),
					},
				}),
			)
		},
	})
}

function destroyScroller() {
	getScroller().kill()
}

window.addEventListener('resize', () => {
	getScroller()?.refresh()
})

window.addEventListener('onNavEnd', () => {
	destroyScroller()
	createScroller()
})

createScroller()
