import {$, Component, ComponentFactory, Query} from '../util'

class FormUpload extends Component {
	$el: Query

	$control: Query<HTMLInputElement>

	$filename: Query

	$form: Query<HTMLFormElement>

	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)
		this.$form = this.$el.closest<HTMLFormElement>('form')
		this.$control = this.$el.query<HTMLInputElement>('input.form-upload__control')
		this.$filename = this.$el.query('.form-upload__name')

		this.$control.on('change', () => {
			this._handleUpload()
		})

		this.$form.on('reset', () => {
			this._handleReset()
		})
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	private _handleUpload() {
		const file = this.$control.el.files[0]
		this.$filename.setText(file.name)
		console.log(file.name)
	}

	private _handleReset() {
		this.$filename.setText('')
	}
}

export default new ComponentFactory({
	selector: '.form-upload',
	component: FormUpload,
})
