import {ResizeEndEvent} from '../types'
import {$BODY, $PAGE_CONTENT} from '../util'

class EventService {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	constructor() {
		this._resize()
		this._unload()
		this._vh()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_resize() {
		const rect = $PAGE_CONTENT.rect()

		let timer: number
		let windowWidth = 0
		let windowHeight = 0
		// let contentWidth = rect.width
		// let contentHeight = rect.height

		// new ResizeObserver(() => {
		// 	const rect = $PAGE_CONTENT.rect()

		// 	window.dispatchEvent(new CustomEvent('onResize'))

		// 	if (contentWidth !== rect.width) {
		// 		contentWidth = rect.width
		// 		window.dispatchEvent(new CustomEvent('onResizeX'))
		// 	}

		// 	if (contentHeight !== rect.height) {
		// 		contentHeight = rect.height
		// 		window.dispatchEvent(new CustomEvent('onResizeY'))
		// 	}
		// }).observe($PAGE_CONTENT.el)

		window.addEventListener('resize', () => {
			if (!windowWidth || !windowHeight) {
				windowWidth = window.innerWidth
				windowHeight = window.innerHeight
			}

			if (timer) {
				clearTimeout(timer)
			}

			timer = setTimeout(() => {
				window.dispatchEvent(
					new CustomEvent<ResizeEndEvent>('onResizeEnd', {
						detail: {
							x: window.innerWidth - windowWidth,
							y: window.innerHeight - windowHeight,
						},
					}),
				)

				windowWidth = 0
				windowHeight = 0
				clearTimeout(timer)
			}, 250)
		})
	}

	_unload() {
		window.addEventListener('beforeunload', () => {
			window.scrollTo(0, 0)
		})
	}

	_vh() {
		let max = window.innerHeight

		$BODY.setStyle('--vh-initial', window.innerHeight + 'px')
		$BODY.setStyle('--vh-max', max + 'px')
		$BODY.setStyle('--vh', window.innerHeight + 'px')

		window.addEventListener('resize', () => {
			$BODY.setStyle('--vh', window.innerHeight + 'px')

			if (max < window.innerHeight) {
				max = window.innerHeight
				$BODY.setStyle('--vh-max', max + 'px')
			}
		})
	}
}

export const evnts = new EventService()
