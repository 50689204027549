import gsap from 'gsap'
import {$, Component, ComponentFactory, isMD, Query} from '../util'

class ContactFormSwitchComponent extends Component {
	$container: Query

	$contact: Query

	$contactScroller: Query

	$contactForms: Query

	$control: Query<HTMLInputElement>

	$formJob: Query

	$formHire: Query

	// ----------------------------------------
	// Computed Properties
	// ----------------------------------------

	get isActive() {
		return this.$control.el.checked
	}

	set isActive(value) {
		this.$control.el.checked = value
	}

	get isDisabled() {
		return this.$control.el.disabled
	}

	set isDisabled(value) {
		this.$control.el.disabled = value
	}

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)
		this.$contact = $('#page-contact')
		this.$contactScroller = this.$contact.query('.contact__scroller')
		this.$contactForms = this.$contact.query('.contact__main')
		this.$control = this.$container.query<HTMLInputElement>('.contact-switch__control')
		this.$formJob = $('#find-a-job-form')
		this.$formHire = $('#hire-talent-form')

		this.$control.on('change', () => {
			this._toggleForm()
		})
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _toggleForm() {
		if (this.isActive) {
			await this._showJobForm()
		} else {
			await this._showHireForm()
		}
	}

	private async _showHireForm() {
		this.isDisabled = true

		this._scrollToForm()

		const tl = this._timeline()
		await tl.reverse(0)

		this.$formHire.addClass('contact-form--active')
		this.$formJob.removeClass('contact-form--active')
		this.isDisabled = false
	}

	private async _showJobForm() {
		this.isDisabled = true

		this._scrollToForm()

		const tl = this._timeline()
		await tl.play()

		this.$formJob.addClass('contact-form--active')
		this.$formHire.removeClass('contact-form--active')
		this.isDisabled = false
	}

	private _scrollToForm() {
		if (isMD()) return

		const top = this.$contactForms.rect().top + this.$contactScroller.el.scrollTop

		this.$contactScroller.el.scroll({
			top: top,
			left: 0,
			behavior: 'smooth',
		})
	}

	private _timeline() {
		const tl = gsap.timeline({paused: true})

		tl.fromTo(
			this.$formJob,
			{
				autoAlpha: 1,
				x: 0,
			},
			{
				duration: 0.2,
				autoAlpha: 0,
				x: 20,
			},
		)

		tl.fromTo(
			this.$formHire,
			{
				autoAlpha: 0,
				x: 20,
			},
			{
				duration: 0.2,
				autoAlpha: 1,
				x: 0,
			},
		)

		return tl.play()
	}
}

export default new ComponentFactory({
	selector: '.contact-switch',
	component: ContactFormSwitchComponent,
})
