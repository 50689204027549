import gsap from 'gsap'
import {ScrollEvent} from '../types'
import {$, Component, ComponentFactory, Query} from '../util'

class HeaderLogoComponent extends Component {
	$logo: Query

	$box: Query

	$letterH: Query

	$letters: Query

	isAnimating = false

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$logo = $(this.el)
		this.$box = this.$logo.query('.logo__box')
		this.$letterH = this.$logo.query('#h1')
		this.$letters = this.$logo.query('#e, #i, #g, #h2, #t, #s')
	}

	onScroll(e: CustomEvent<ScrollEvent>) {
		const top = e.detail.top

		if (top >= 120) {
			this._shrink()
		} else {
			this._grow()
		}
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _grow() {
		if (this.isAnimating) return
		if (!this.$logo.hasClass('logo--shrink')) return

		this.isAnimating = true

		const tl = this._timeline()
		await tl.reverse(0)

		this.$logo.removeClass('logo--shrink')
		this.isAnimating = false
	}

	private async _shrink() {
		if (this.isAnimating) return
		if (this.$logo.hasClass('logo--shrink')) return

		this.isAnimating = true

		const tl = this._timeline()
		await tl.play()

		this.$logo.addClass('logo--shrink')
		this.isAnimating = false
	}

	private _timeline() {
		const tl = gsap.timeline({
			paused: true,
		})

		const letterWidth = this.$letterH.rect().width
		const logoPadding = parseInt(this.$logo.getStyle('padding-left'))
		const width = letterWidth + logoPadding * 2

		tl.fromTo(
			this.$letters,
			{
				opacity: 1,
			},
			{
				duration: 0.1,
				stagger: -0.05,
				opacity: 0,
			},
			0,
		)

		tl.fromTo(
			this.$box,
			{
				width: '100%',
			},
			{
				duration: 0.15,
				width: width,
			},
			0.2,
		)

		return tl.play()
	}
}

export default new ComponentFactory({
	selector: '#page-header .logo',
	component: HeaderLogoComponent,
})
