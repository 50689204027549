import getScroller from '../services/scroller'
import {$, Component, ComponentFactory, Query} from '../util'

class BackgroundComponent extends Component {
	$el: Query

	// ----------------------------------------
	// Lifecycle methods
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)

		this.$el.css({
			'will-change': 'transform',
		})

		this._setOffset()
	}

	onResizeEnd() {
		this._setOffset()
	}

	onScroll() {
		this._setOffset()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_setOffset() {
		const scroller = getScroller()
		this.$el.setStyle('transform', `translate3d(0, ${scroller.scrollTop()}px, 0)`)
	}
}

export default new ComponentFactory({
	selector: '#page-texture, #page-triangle',
	component: BackgroundComponent,
})
