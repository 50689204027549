import mixitup from 'mixitup'
import mixitupMultifilter from 'mixitup-multifilter'
import getScroller from '../services/scroller'
import {$, Component, ComponentFactory, Query} from '../util'

mixitup.use(mixitupMultifilter)

class FilterComponent extends Component {
	$container: Query

	$clear?: Query

	$filters?: Query<HTMLFormElement>

	mixer: mixitup.Mixer

	onInit() {
		this.$container = $(this.el)
		this.$clear = this.$container.getData('clear') ? $(this.$container.getData('clear')) : null
		this.$filters = this.$container.getData('filters') ? $<HTMLFormElement>(this.$container.getData('filters')) : null

		const params = new URLSearchParams(window.location.search)

		const config = {
			animation: {
				enable: true,
			},
			controls: {
				live: true,
				toggleLogic: 'and',
			},
			multifilter: {
				enable: true,
				logicWithinGroup: 'and',
				logicBetweenGroups: 'and',
			},
			selectors: {
				target: this.$container.getData('target'),
			},
			callbacks: {
				onMixEnd: (state) => {
					if (!this.$container.getData('msg-no-results')) return

					const $msg = $(this.$container.getData('msg-no-results'))

					if (state.hasFailed) {
						$msg.fadeIn()
					} else {
						$msg.fadeOut()
					}

					getScroller()?.refresh()
				},
			},
		}

		this.mixer = mixitup(this.$container.el, config)

		this.$clear.on('click', (e) => {
			e.preventDefault()
			this.$filters.el.reset()
		})

		if (this.$container.getData('filterable') === params.get('filter')) {
			params.delete('filter')

			params.forEach((value, key) => {
				this.mixer.setFilterGroupSelectors(key, `.${value}`)
				this.mixer.parseFilterGroups()
			})
		}
	}

	onNavStart() {
		this.mixer.destroy()
	}
}

export default new ComponentFactory({
	selector: '[data-filterable]',
	component: FilterComponent,
})
