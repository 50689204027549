import gsap from 'gsap'
import getScroller from '../services/scroller'
import {$, $BODY, Component, ComponentFactory, Query} from '../util'

class ModalComponent extends Component {
	$modal: Query

	isActive = false

	isLoading = false

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$modal = $(this.el)
		this.$modal.on('toggleModal', () => this._toggleModal())
		this.$modal.on('closeModal', () => this._closeModal())
		this.$modal.on('openModal', () => this._openModal())
	}

	onKeydown(e: KeyboardEvent) {
		if (!this.isActive) return

		if (e.key === 'Escape') {
			this._closeModal()
		}
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _toggleModal() {
		if (this.isActive) {
			await this._closeModal()
		} else {
			await this._openModal()
		}
	}

	private async _openModal() {
		if (this.isLoading) return

		getScroller().paused(true)

		this.isLoading = true
		this.$modal.emit('modalWillOpen')

		const tl = this._timeline()
		await tl.play()

		this.isActive = true
		this.isLoading = false
		this.$modal.emit('modalDidOpen')
		this._updateLocation()
		this._updateIframe()
	}

	private async _closeModal() {
		if (this.isLoading) return

		this.isLoading = true
		this.$modal.emit('modalWillClose')

		const tl = this._timeline()
		await tl.reverse(0)

		this.isActive = false
		this.isLoading = false
		this.$modal.emit('modalDidClose')
		this._updateLocation()
		this._updateIframe()

		getScroller().paused(false)
	}

	private _timeline() {
		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				$BODY.addClass('modal-active')
				this.$modal.addClass('modal--active')
			},
			onReverseComplete: () => {
				$BODY.removeClass('modal-active')
				this.$modal.removeClass('modal--active')
			},
		})

		tl.fromTo(
			this.$modal,
			{
				display: 'none',
				opacity: 0,
			},
			{
				duration: 0.3,
				display: 'block',
				opacity: 1,
			},
		)

		return tl.play()
	}

	private _updateLocation() {
		const slug = this.$modal.getData('slug') || null
		const hash = this.$modal.getData('hash') || null
		const isActive = this.$modal.hasClass('modal--active')

		if (slug) {
			let url = window.location.origin
			const path = window.location.pathname.split('/')

			url += path.filter((s) => s !== slug).join('/')

			if (isActive) {
				url += url.endsWith('/') ? slug : `/${slug}`
			}

			if (url !== window.location.href) {
				history.pushState(null, null, url)
			}

			return
		}

		if (hash) {
			if (isActive) {
				history.pushState(null, null, `#${hash}`)
			} else {
				history.pushState(null, null, `${window.location.pathname}`)
			}

			return
		}
	}

	private _updateIframe() {
		const isActive = this.$modal.hasClass('-active')
		const $iframe = this.$modal.query('iframe[data-src]')

		if (isActive) {
			$iframe.forEach((iframe) => {
				iframe.setAttribute('src', iframe.dataset.src)
			})
		} else {
			$iframe.removeAttr('src')
		}
	}
}

export default new ComponentFactory({
	selector: '.modal',
	component: ModalComponent,
})
