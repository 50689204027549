import gsap from 'gsap'
import {$, $BODY, Component, ComponentFactory, Query} from '../util'

class HeaderComponent extends Component {
	$header: Query

	$nav: Query

	$toggle: Query

	isOpen = false

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$header = $(this.el)
		this.$nav = this.$header.query('.nav')
		this.$toggle = this.$header.query('.hamburger')

		this.$toggle.on('click', (e) => {
			e.preventDefault()
			this._toggleNav()
		})

		this.$header.on('toggleNav', () => this._toggleNav())
		this.$header.on('closeNav', () => this._closeNav())
		this.$header.on('openNav', () => this._openNav())
	}

	onResize() {
		if (this.isOpen) {
			this._closeNav()
		}
	}

	onNavStart() {
		if (this.isOpen) {
			this._closeNav()
		}
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _toggleNav() {
		if (this.isOpen) {
			await this._closeNav()
		} else {
			await this._openNav()
		}
	}

	private async _openNav() {
		this.isOpen = true
		this.$toggle.addClass('hamburger--active')

		const tl = this._timeline()
		await tl.play()
	}

	private async _closeNav() {
		this.isOpen = false
		this.$toggle.removeClass('hamburger--active')

		const tl = this._timeline()
		await tl.reverse(0)
	}

	private _timeline() {
		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				$BODY.addClass('nav-active')
				this.$nav.addClass('nav--active')
			},
			onReverseComplete: () => {
				$BODY.removeClass('nav-active')
				this.$nav.removeClass('nav--active')
			},
		})

		tl.set(this.$nav, {
			clearProps: 'all',
		})

		tl.fromTo(
			this.$nav,
			{
				display: 'none',
				xPercent: 100,
			},
			{
				clearProps: 'all',
				duration: 0.3,
				display: 'block',
				xPercent: 0,
			},
			0,
		)

		return tl.play()
	}
}

export default new ComponentFactory({
	selector: '#page-header',
	component: HeaderComponent,
})
