import {$, Component, ComponentFactory, Query} from '../util'

class JobGridComponent extends Component {
	$container: Query

	$listings: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)
		this.$listings = this.$container.query('.job-card')

		this._calcHeight()
	}

	onResize() {
		this._calcHeight()
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _calcHeight() {
		let maxHeight = 0

		this.$container.removeStyle('--job-card-height')

		this.$listings.forEach((el: HTMLElement) => {
			el.style.removeProperty('height')
			maxHeight = el.offsetHeight > maxHeight ? el.offsetHeight : maxHeight
		})

		this.$container.setStyle('--job-card-height', `${maxHeight}px`)
	}
}

export default new ComponentFactory({
	selector: '#job-grid',
	component: JobGridComponent,
})
