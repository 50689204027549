import gsap from 'gsap'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import {$, $BODY, Component, ComponentFactory, isMD, matches, Query} from '../util'

gsap.registerPlugin(ScrollSmoother)

export class FooterComponent extends Component {
	$footer: Query

	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	onInit() {
		this.$footer = $(this.el)
		this._resizeSpacer()
	}

	onResize() {
		this._resizeSpacer()
		this._setOffset()
	}

	onScroll() {
		this._setOffset()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_resizeSpacer() {
		const height = this.$footer.rect().height
		$BODY.setStyle('--footer-height', `${height}px`)
	}

	_setOffset() {
		const scroller = ScrollSmoother.get()

		if (!scroller) return

		if (isMD() && matches('(min-height: 768px)')) {
			$BODY.addClass('footer-fixed')
			this.$footer.setStyle('transform', `translate3d(0, ${scroller.scrollTop()}px, 0)`)
		} else {
			$BODY.removeClass('footer-fixed')
			this.$footer.setStyle('transform', `none`)
		}
	}
}

export default new ComponentFactory({
	selector: '#page-footer',
	component: FooterComponent,
})
