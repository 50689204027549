import axios from 'redaxios'
import {$, $PAGE_OVERLAYS, Component, ComponentFactory, Query} from '../util'

let loading = false

;(() => {
	window.addEventListener('load', checkWindowState)

	window.addEventListener('onUpdate', checkWindowState)

	function checkWindowState() {
		const slug = window.location.pathname.slice(1).split('/').pop()
		const $card = $(`.job-card > a[data-slug="${slug}"`)

		if (!$card.any) return

		const $modal = $(`#modal-${$card.getData('id')}`)

		if (!$modal.any) return

		$modal.emit('openModal')
	}
})()

class JobsListingComponent extends Component {
	$card: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$card = $(this.el)

		this.$card.on('click', (e) => {
			e.preventDefault()
			this._loadJobListing()
		})
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _loadJobListing() {
		if (loading) return

		loading = true
		this.$card.addClass('card--loading')

		const id = this.$card.getData('id')
		const slug = this.$card.getData('slug')
		let $modal = $(`#modal-${id}`)

		if ($modal.any) {
			loading = false
			$modal.emit('openModal')
			this.$card.removeClass('card--loading')
			return
		}

		window.dispatchEvent(new CustomEvent('onLoadStart'))

		const {data} = await axios<string>({
			method: 'get',
			url: `${window.location.origin}/jobs/${slug}`,
		})

		const parser = new DOMParser()
		const newDoc = parser.parseFromString(data, 'text/html')
		const newOverlays = newDoc.querySelector('#page-overlays')

		Array.from(newOverlays.children).forEach((el: HTMLElement) => {
			$PAGE_OVERLAYS.append('beforeend', el)
		})

		loading = false
		this.$card.removeClass('card--loading')

		window.dispatchEvent(new CustomEvent('onUpdate'))
		window.dispatchEvent(new CustomEvent('onLoadEnd'))

		$modal = $(`#modal-${id}`)
		$modal.emit('openModal')
	}
}

export default new ComponentFactory({
	selector: '.job-card > a.card',
	component: JobsListingComponent,
})
