export const isTouchEnabled = () => matchMedia('(pointer:coarse)').matches && !matchMedia('(hover)').matches

export const isStylusEnabled = () => matchMedia('(pointer:fine)').matches && !matchMedia('(hover)').matches

export const isMouseEnabled = () => matchMedia('(pointer:fine)').matches && matchMedia('(hover)').matches

export const isSM = () => matches('(min-width: 576px)')

export const isMD = () => matches('(min-width: 768px)')

export const isLG = () => matches('(min-width: 992px)')

export const isXL = () => matches('(min-width: 1200px)')

export const matches = (mediaQuery: string) => matchMedia(mediaQuery).matches

export function throttle(fn, wait: number) {
	let time = Date.now()

	return function () {
		if (time + wait - Date.now() < 0) {
			fn()
			time = Date.now()
		}
	}
}

// HACK: getBoundingClientRect would have been cleaner, but IOS sucks...
export function getOffsetTop(el: HTMLElement) {
	let top = 0

	while (el) {
		if (el && el.offsetTop > 0) {
			top = top + el.offsetTop
		}

		el = el.offsetParent as HTMLElement
	}

	return top
}
