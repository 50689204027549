import {gsap} from 'gsap'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import axios from 'redaxios'
import {$, $BODY, $PAGE_CONTENT, $PAGE_OVERLAYS, GTAG_ID} from '../util'

gsap.registerPlugin(ScrollSmoother)

const scroller = ScrollSmoother.get()

class RouterService {
	loading = false

	constructor() {
		$BODY.on('click', async (e) => {
			if (!e.target || !(e.target instanceof Element)) return false

			const target = e.target
			const anchor = target.closest('a')

			if (!anchor) return false

			const href = anchor.getAttribute('href')
			const path = href.split(/[?#]+/)[0]
			const params = href.split(/[?#]+/)[1] || null
			const host = anchor.host

			const currentHref = window.location.href
			const currentPath = currentHref.split(/[?#]+/)[0]
			const currentParams = currentHref.split(/[?#]+/)[1] || null
			const currentHost = window.location.host

			if (href === '#') {
				// console.log('Link href matches #')
				e.preventDefault()
				return false
			}

			if (anchor.dataset.ignore || anchor.dataset.toggle) {
				// console.log('Link contains a forbidden data attribute')
				return false
			}

			if (anchor.getAttribute('role') === 'button') {
				// console.log('Link has a forbidden role')
				return false
			}

			if (currentHost !== host) {
				// console.log('Link is an external link')
				return false
			}

			if (currentPath === path) {
				// console.log('Link is to current page')
				e.preventDefault()

				if (params && params !== currentParams) {
					window.history.pushState('', '', href)

					if (href.indexOf('#') > 0) window.dispatchEvent(new CustomEvent('hash-updated', {detail: new URLSearchParams(params)}))
					if (href.indexOf('?') > 0) window.dispatchEvent(new CustomEvent('params-updated', {detail: new URLSearchParams(params)}))
				}

				return false
			}

			if (href.indexOf('tel:') >= 0 || href.indexOf('mailto:') >= 0) {
				// console.log('Link is an email or tel link')
				return false
			}

			e.preventDefault()

			this.to(href)
		})
	}

	// ----------------------------------------
	// Public Methods
	// ----------------------------------------

	async to(href: string) {
		if (this.loading) return

		this.loading = true
		window.dispatchEvent(new CustomEvent('onNavStart'))
		window.dispatchEvent(new CustomEvent('onLoadStart'))
		$BODY.addClass('loading')

		this._updateActiveState(href)

		if (typeof window.history.pushState === 'function') {
			window.history.pushState('', '', href)
		}

		scroller.paused(false)
		scroller.scrollTo(document.body, true)

		// $PAGE_LOADER.emit('loaderReset')

		const [data] = await Promise.all([this._fetchData(href), this._animateOut()])
		await this._render(data)

		window.dispatchEvent(new CustomEvent('onUpdate'))

		scroller.refresh()

		await this._animateIn()

		this.loading = false
		window.dispatchEvent(new CustomEvent('onNavEnd'))
		window.dispatchEvent(new CustomEvent('onLoadEnd'))
		$BODY.removeClass('loading')

		// Trigger gtag
		if (gtag && typeof gtag === 'function') {
			gtag('config', GTAG_ID, {
				page_title: document.title,
				page_path: href.split('.co.nz').pop(),
			})
		}

		// $PAGE_LOADER.emit('loaderStart').on('loaderProgress', async (e: CustomEvent<number>) => {
		// 	if (e.detail < 100) return
		// 	await this._animateIn()

		// 	this.loading = false
		// 	window.dispatchEvent(new CustomEvent('onNavEnd'))
		// 	window.dispatchEvent(new CustomEvent('onLoadEnd'))
		// 	$BODY.removeClass('loading')
		// })
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private async _animateOut() {
		const tl = gsap.timeline({paused: true})

		tl.to($PAGE_CONTENT, {
			duration: 0.5,
			autoAlpha: 0,
		})

		// tl.to($PAGE_LOADER, {
		// 	duration: 0.5,
		// 	autoAlpha: 1,
		// })

		await tl.play()
	}

	private async _animateIn() {
		const tl = gsap.timeline({paused: true})

		// tl.to($PAGE_LOADER, {
		// 	duration: 0.5,
		// 	autoAlpha: 0,
		// })

		tl.to($PAGE_CONTENT, {
			duration: 0.5,
			autoAlpha: 1,
		})

		await tl.play()
	}

	private async _fetchData(href: string) {
		const {data} = await axios.get(href, {
			method: 'get',
			validateStatus: (status) => {
				return status === 200 || status === 404
			},
		})

		return data
	}

	private async _render(data: string) {
		const parser = new DOMParser()
		const newDoc = parser.parseFromString(data, 'text/html')
		const newBody = newDoc.querySelector('body')
		const newContent = newDoc.querySelector('#page-content')
		const newOverlays = newDoc.querySelector('#page-overlays')
		const title = newDoc.querySelector('title').textContent

		// Replace the old content with the new content
		document.title = title

		$PAGE_CONTENT.el.innerHTML = newContent.innerHTML
		$PAGE_OVERLAYS.el.innerHTML = newOverlays.innerHTML
		$BODY.setAttr('class', newBody.getAttribute('class'))
	}

	private _updateActiveState(href: string) {
		const $links = $('.nav-link')

		$links.forEach((link) => {
			if (link.getAttribute('href') === href) {
				link.classList.add('nav-link--active')
			} else {
				link.classList.remove('nav-link--active')
			}
		})
	}
}

export const router = new RouterService()
