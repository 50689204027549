import getScroller from '../services/scroller'
import {$, $PAGE_HEADER, Component, ComponentFactory, getOffsetTop, Query} from '../util'

class StickyComponent extends Component {
	$container: Query

	$el: Query

	$spacer: Query

	isSticky = false

	offset = 0

	observer = new ResizeObserver(() => {
		this._updateSpacer()
		this._updateOffset()
		this._updatePosition()
	})

	// ----------------------------------------
	// Computed Properties
	// ----------------------------------------

	get scrollTop() {
		return getScroller().scrollTop()
	}

	get containerTop() {
		return getOffsetTop(this.$container.el)
	}

	get containerHeight() {
		return this.$container.el.offsetHeight
	}

	get elTop() {
		return this.scrollTop + getOffsetTop(this.el)
	}

	get elHeight() {
		return this.el.offsetHeight
	}

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)

		if (!this.$el.any) {
			throw `No sticky element was found, received: ${this.el}`
		}

		this.$container = $(this.el.parentElement)

		if (!this.$container.any) {
			throw `No container was found for sticky element: ${this.el}`
		}

		this.$container.addClass('sticky-container')
		this.$container.css({
			position: 'relative',
		})

		this.$el.addClass('sticky')
		this.$el.css({
			position: 'absolute',
			top: '0px',
			transform: 'translate3d(0, 0, 0)',
		})

		this.$spacer = this.$el.prependHTML('beforebegin', `<div class="sticky-spacer"></div>`)

		this._updateSpacer()
		this._updateOffset()
		this._updatePosition()

		this.observer.observe(this.el)
	}

	onDestroy() {
		this.observer.unobserve(this.el)
		this.observer.disconnect()
	}

	onScroll() {
		this._updateSpacer()
		this._updateOffset()
		this._updatePosition()
	}

	onResize() {
		this._updateSpacer()
		this._updateOffset()
		this._updatePosition()
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	/**
	 * Reset the pinned element's styles
	 */
	private _reset() {
		this.isSticky = false

		this.$el.css({
			position: 'absolute',
			top: '0px',
			transform: 'translate3d(0, 0, 0)',
		})
	}

	/**
	 * Update the offset to properly align the pinned element while scrolling
	 */
	private _updateOffset() {
		// Get config attributes
		const anchor = this.$el.getData('sticky') || 'top'
		const offsetHeader = this.$el.hasData('offset-header') ? this.$el.getData('offset-header') === 'true' : false

		// Calc heights
		const windowHeight = document.documentElement.clientHeight
		const headerHeight = $PAGE_HEADER.el.offsetHeight

		let value = 0

		switch (anchor) {
			case 'bottom':
				value = windowHeight - this.elHeight
				if (offsetHeader) value += headerHeight
				break

			case 'middle':
				value = (windowHeight - this.elHeight) / 2
				if (offsetHeader) value += headerHeight / 2
				break

			case 'top':
			default:
				value = 0
				if (offsetHeader) value += headerHeight
				break
		}

		this.offset = value
		this.$container.setStyle('--sticky-offset', `${value}px`)
	}

	/**
	 * Update the element styles based on the scroll position
	 */
	private _updatePosition() {
		const top = this.containerTop - this.scrollTop - this.offset
		const bottom = top + this.containerHeight - this.elHeight

		// console.log(top, this.$container.rect().top)

		// if the parent has not reached the top of the window
		// or the parent is too small for the pinned el to stick
		if (top > 0 || this.containerHeight <= this.elHeight) {
			if (this.isSticky) {
				this._reset()
				this.$el.emit('onUnstick')
				this.$el.removeClass('sticky--active')
			}
			return
		}

		if (!this.isSticky) {
			this.isSticky = true
			this.$el.emit('onStick')
			this.$el.addClass('sticky--active')
		}

		if (bottom > 0) {
			this.$el.css({
				top: '0px',
				bottom: 'unset',
				transform: `translate3d(0, ${Math.abs(top)}px, 0)`,
			})
		} else {
			this.$el.css({
				top: 'unset',
				bottom: '0px',
				transform: `translate3d(0, 0, 0)`,
			})
		}
	}

	/**
	 * Update the styles based on the pinned element's size
	 */
	private _updateSpacer() {
		this.$spacer.css({
			width: `calc(${this.el.offsetWidth}px + var(--sticky-offset-x, 0px))`,
			height: `calc(${this.el.offsetHeight}px + var(--sticky-offset-y, 0px))`,
		})
	}
}

export default new ComponentFactory({
	selector: '[data-sticky]',
	component: StickyComponent,
})
