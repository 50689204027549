import gsap from 'gsap'
import getScroller from '../services/scroller'
import {$, $BODY, Component, ComponentFactory, isMD, Query} from '../util'

class ContactComponent extends Component {
	$bgColumns: Query

	$backdrop: Query

	$closeBtn: Query

	$contact: Query

	$content: Query

	$header: Query

	isActive = false

	isLoading = false

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$contact = $(this.el)
		this.$header = this.$contact.query('.contact__header')
		this.$content = this.$contact.query('.contact__content')
		this.$backdrop = this.$contact.query('.contact__backdrop')
		this.$bgColumns = this.$contact.query('.contact__bg__col')
		this.$closeBtn = this.$contact.query('.contact__close')

		this.$contact.on('toggleContact', () => this._toggleContact())
		this.$contact.on('closeContact', () => this._closeContact())
		this.$contact.on('openContact', () => this._openContact())
		this.$backdrop.on('click', () => this._closeContact())

		this._checkHash()
	}

	onNavEnd() {
		this._checkHash()
	}

	onKeydown(e: KeyboardEvent) {
		if (!this.isActive) return

		if (e.key === 'Escape') {
			this._closeContact()
		}
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _checkHash() {
		if (window.location.hash !== '#contact') return

		setTimeout(() => {
			this._openContact()
			history.replaceState(null, null, ' ')
		}, 1000)
	}

	private async _toggleContact() {
		if (this.isActive) {
			await this._closeContact()
		} else {
			await this._openContact()
		}
	}

	private async _openContact() {
		if (this.isLoading) return

		getScroller().paused(true)

		this.isLoading = true
		this.$contact.addClass('contact--active')

		const tl = this._timeline()
		await tl.play()

		this.isActive = true
		this.isLoading = false
	}

	private async _closeContact() {
		if (this.isLoading) return

		this.isLoading = true

		const tl = this._timeline()
		await tl.reverse(0)

		this.isActive = false
		this.isLoading = false
		this.$contact.removeClass('contact--active')

		getScroller().paused(false)
	}

	private _timeline() {
		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				$BODY.addClass('contact-active')
			},
			onReverseComplete: () => {
				$BODY.removeClass('contact-active')
			},
		})

		if (isMD()) {
			tl.set(this.$contact, {
				clearProps: 'all',
			})

			tl.fromTo(
				this.$backdrop,
				{
					autoAlpha: 0,
				},
				{
					clearProps: 'all',
					autoAlpha: 1,
				},
				0,
			)

			tl.fromTo(
				this.$bgColumns.reverse(),
				{
					clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
				},
				{
					clearProps: 'all',
					duration: 0.5,
					stagger: 0.06,
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
				},
				0,
			)

			tl.addLabel('background-visible', '+=0.15')

			tl.fromTo(
				this.$closeBtn,
				{
					autoAlpha: 0,
				},
				{
					clearProps: 'all',
					duration: 0.5,
					autoAlpha: 1,
				},
				'background-visible',
			)

			tl.fromTo(
				this.$header,
				{
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
				},
				{
					clearProps: 'all',
					duration: 0.5,
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
				},
				'background-visible',
			)

			tl.fromTo(
				this.$content,
				{
					autoAlpha: 0,
				},
				{
					clearProps: 'all',
					duration: 0.5,
					autoAlpha: 1,
				},
				'background-visible',
			)
		} else {
			tl.set([this.$backdrop, this.$bgColumns, this.$closeBtn, this.$contact, this.$content, this.$header], {
				clearProps: 'all',
			})

			tl.fromTo(
				this.$contact,
				{
					xPercent: 100,
				},
				{
					clearProps: 'all',
					duration: 0.3,
					xPercent: 0,
				},
				0,
			)
		}

		return tl.play()
	}
}

export default new ComponentFactory({
	selector: '#page-contact',
	component: ContactComponent,
})
