import axios from 'redaxios'
import {$, Component, ComponentFactory, Query, RECAPTCHA_KEY} from '../util'

interface FormResponse {
	success: boolean
	returnUrl: string | null
	formErrors: string[]
	errors: Record<string, string[]>
}

interface RecaptchaResponse {
	success: boolean
	challenge_ts: string
	hostname: string
	score: number
	action: 'submit'
}

export class FormComponent extends Component<HTMLFormElement> {
	$form: Query<HTMLFormElement>

	$formAlertSuccess: Query

	$formAlertError: Query

	$formContent: Query

	$formGroups: Query

	$formSubmit: Query<HTMLButtonElement>

	url: string

	onInit() {
		this.$form = $(this.el)
		this.$formAlertSuccess = this.$form.query(`#${this.el.id}-alert-success`)
		this.$formAlertError = this.$form.query(`#${this.el.id}-alert-error`)
		this.$formContent = this.$form.query('.form__content')
		this.$formGroups = this.$form.query('.form-group')
		this.$formSubmit = this.$form.query<HTMLButtonElement>('[type="submit"]')
		this.url = this.$form.getAttr('action') || window.location.href

		this.$form.setData('init', 'true')

		this.$formSubmit.removeAttr('disabled')

		this.$form.on('submit', async (e) => {
			e.preventDefault()
			this.onSubmit()
		})
	}

	// ----------------------------------------
	// Public methods
	// ----------------------------------------

	public async onSubmit() {
		this._disableForm()

		const token = await this._validateRecaptcha()
		// console.log(token)

		if (token) {
			await this._handleSubmit(token)
		}

		this._enableForm()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	private _disableForm() {
		this.$formSubmit.setAttr('disabled', 'true')
		this.$formSubmit.addClass('btn--loading')
	}

	private _enableForm() {
		this.$formSubmit.removeAttr('disabled')
		this.$formSubmit.removeClass('btn--loading')
	}

	private async _handleSubmit(token: string) {
		const $formMessages = this.$form.query('.form-group__messages')
		const formData = new FormData(this.$form.el as HTMLFormElement)

		formData.append('token', token)

		// submit data
		const {data} = await axios<FormResponse>({
			url: this.url,
			method: 'post',
			data: formData,
			headers: {
				'Cache-Control': 'no-cache',
				'X-Requested-With': 'XMLHttpRequest',
				'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
			},
		})

		// handle success
		if (data.success) {
			$formMessages.empty()
			this.$formAlertSuccess.removeClass('d-none')
			this.$formAlertError.addClass('d-none')
			this.$formContent.remove()
			return
		}

		// handle errors
		$formMessages.empty()
		this.$formAlertSuccess.addClass('d-none')
		this.$formAlertError.removeClass('d-none')
		this.$formGroups.removeClass('form-group--error')

		for (const [name, message] of Object.entries(data.errors)) {
			const $group = this.$form.query(`#${this.el.id}-group-${name}`)
			const $messages = $group.query('.form-group__messages')

			$group.addClass('form-group--error')
			$messages.appendHTML('beforeend', `<p>${message}</p>`)
		}
	}

	private _validateRecaptcha(): Promise<string> {
		return new Promise((resolve, reject) => {
			if (!RECAPTCHA_KEY) {
				reject('No recaptcha key provided')
			}

			grecaptcha.ready(async () => {
				const token = await grecaptcha.execute(RECAPTCHA_KEY, {
					action: 'submit',
				})

				resolve(token)
			})
		})
	}
}

export default new ComponentFactory({
	selector: 'form.form, .form > form',
	component: FormComponent,
})
