import {ScrollEvent} from '../types'

type Constructor<T> = new (el: Element) => T

interface ComponentFactoryOptions {
	/**
	 * The css selector used find the component element(s).
	 */
	selector: string

	/**
	 * The component class to instantiate.
	 */
	component: Constructor<Component<Element>>
}

interface ComponentEvent {
	eventName: string
	eventHost: Window | Document | HTMLElement
	method: string
}

const EVNTS: ComponentEvent[] = [
	{
		eventName: 'keydown',
		eventHost: document,
		method: 'onKeydown',
	},
	{
		eventName: 'onLoadEnd',
		eventHost: window,
		method: 'onLoadEnd',
	},
	{
		eventName: 'onLoadStart',
		eventHost: window,
		method: 'onLoadStart',
	},
	{
		eventName: 'onNavEnd',
		eventHost: window,
		method: 'onNavEnd',
	},
	{
		eventName: 'onNavStart',
		eventHost: window,
		method: 'onNavStart',
	},
	{
		eventName: 'resize',
		eventHost: window,
		method: 'onResize',
	},
	{
		eventName: 'onResizeEnd',
		eventHost: window,
		method: 'onResizeEnd',
	},
	// {
	// 	eventName: 'onScroll',
	// 	eventHost: window,
	// 	method: 'onScroll',
	// },
	{
		eventName: 'onUpdate',
		eventHost: window,
		method: 'onUpdate',
	},
]

/**
 * The base component class.
 */
export class Component<T extends Element = HTMLElement> {
	/**
	 * The root element of the component.
	 */
	el: T

	constructor(el: T) {
		this.el = el
	}

	/**
	 * A callback method for the OnDestroy event.
	 * Invoked when a component class is destroyed.
	 */
	onDestroy?(): void

	/**
	 * A callback method for the OnInit event.
	 * Invoked when a component class is initialized.
	 */
	onInit?(): void

	/**
	 * A callback method for the OnKeydown event.
	 * Invoked when a key is pressed.
	 */
	onKeydown?(e: KeyboardEvent): void

	/**
	 * A callback method for the OnLoadStart event.
	 * Invoked when the app has finished loading data e.g. from an api.
	 */
	onLoadEnd?(e: Event): void

	/**
	 * A callback method for the OnLoadStart event.
	 * Invoked when the app is loading data e.g. from an api.
	 */
	onLoadStart?(e: Event): void

	/**
	 * A callback method for the OnNavEnd event.
	 * Invoked when the router has finished loading the new page.
	 */
	onNavEnd?(e: Event): void

	/**
	 * A callback method for the OnNavStart event.
	 * Invoked when the router has started loading the new page.
	 */
	onNavStart?(e: Event): void

	/**
	 * A callback method for the OnResize event.
	 * Invoked when the window is resized horizontally or vertically.
	 */
	onResize?(e: Event): void

	/**
	 * A callback method for the onResizeEnd event.
	 * Invoked when the window has stopped being resized.
	 */
	onResizeEnd?(e: Event): void

	/**
	 * A callback method for the OnScroll event.
	 * Invoked when the scroll-smoother has been updated.
	 */
	onScroll?(e: CustomEvent<ScrollEvent>): void

	/**
	 * A callback method for the OnUpdate event.
	 * Invoked when the app has updated the DOM.
	 */
	onUpdate?(e: Event): void
}

/**
 * The ComponentFactory instantiates a new instance of a component class for each Element that matches the selector.
 */
export class ComponentFactory {
	private _components: Component<Element>[] = []

	private _constructor: Constructor<Component<Element>>

	private _selector: string

	constructor(options: ComponentFactoryOptions) {
		this._constructor = options.component
		this._selector = options.selector

		window.addEventListener('DOMContentLoaded', () => {
			this._update()
		})

		window.addEventListener('onUpdate', () => {
			this._update()
		})

		// Setup event listeners based on the prototype methods
		EVNTS.forEach((evnt) => {
			if (!(evnt.method in this._constructor.prototype)) return

			evnt.eventHost.addEventListener(evnt.eventName, (e) => {
				this._components.forEach((component) => {
					component[evnt.method](e)
				})
			})
		})
	}

	private _update() {
		// Destroy components whose element no longer exists
		this._components = this._components.filter((component) => {
			const isAlive = document.body.contains(component.el)

			if (!isAlive && 'onDestroy' in component) {
				component.onDestroy()
			}

			return isAlive
		})

		// Create new components
		const els = document.querySelectorAll(`${this._selector}:not([component*="${this._constructor.name}"])`)

		els.forEach((el) => {
			if (el.hasAttribute('component')) {
				el.setAttribute('component', `${el.getAttribute('component')} ${this._constructor.name}`)
			} else {
				el.setAttribute('component', this._constructor.name)
			}

			const component = new this._constructor(el)

			if ('onInit' in component) {
				component.onInit()
			}

			if ('onScroll' in component) {
				window.addEventListener('onScroll', (e: CustomEvent<ScrollEvent>) => {
					component.onScroll(e)
				})
			}

			this._components.push(component)
		})
	}
}
