import Swiper, {FreeMode} from 'swiper'
import {$, Component, ComponentFactory, isLG, Query} from '../util'

class JobsPointSliderComponent extends Component {
	$container: Query

	swiper?: Swiper

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)

		isLG() ? this._destroySlider() : this._initSlider()
	}

	onResize() {
		isLG() ? this._destroySlider() : this._initSlider()
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _initSlider() {
		this.$container.removeClass('no-slide')

		this.swiper = new Swiper(this.el, {
			freeMode: true,
			modules: [FreeMode],
			slidesPerView: 'auto',
		})
	}

	private _destroySlider() {
		this.$container.addClass('no-slide')
		this.swiper?.destroy()
		this.swiper = undefined
	}
}

export default new ComponentFactory({
	selector: '.points-slider',
	component: JobsPointSliderComponent,
})
