import gsap from 'gsap'
import getScroller from '../services/scroller'
import {$, Component, ComponentFactory, Query} from '../util'

class ScrollToComponent extends Component {
	$el: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)

		this.$el.on('click', (e) => {
			e.preventDefault()

			const selector = this.$el.getData('scroll-to')

			if (selector) {
				const scroller = getScroller()

				gsap.to(scroller, {
					scrollTop: scroller.offset(selector, 'top'),
					duration: 0.4,
				})
			}
		})
	}
}

export default new ComponentFactory({
	selector: '[data-scroll-to]',
	component: ScrollToComponent,
})
