import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {$, Component, ComponentFactory, Query} from '../util'

gsap.registerPlugin(ScrollTrigger)

class HomeContentComponent extends Component {
	$el: Query

	$maskContainer: Query

	$mask: Query

	$title1: Query

	$title2: Query

	$videoContainer: Query

	$video: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)
		this.$maskContainer = this.$el.query('.home-content__mask')
		this.$mask = this.$el.query('.home-mask')
		this.$title1 = this.$el.query('.home-title:nth-child(1)')
		this.$title2 = this.$el.query('.home-title:nth-child(2)')
		this.$videoContainer = this.$el.query('.home-content__video')
		this.$video = this.$el.query('.home-video')

		this.$title1
			.on('mouseenter', () => this.$mask.addClass('home-mask--offset-left'))
			.on('mouseleave', () => this.$mask.removeClass('home-mask--offset-left'))

		this.$title2
			.on('mouseenter', () => this.$mask.addClass('home-mask--offset-right'))
			.on('mouseleave', () => this.$mask.removeClass('home-mask--offset-right'))

		this.$videoContainer
			.on('onStick', () => this.$video.addClass('home-video--expanded'))
			.on('onUnstick', () => this.$video.removeClass('home-video--expanded'))
	}
}

export default new ComponentFactory({
	selector: '.home-content',
	component: HomeContentComponent,
})
