import Swiper, {Navigation, Pagination, SwiperOptions} from 'swiper'
import {$, Component, ComponentFactory, Query} from '../util'

class SliderComponent extends Component {
	$container: Query

	$slides: Query

	$next?: Query

	$pagination?: Query

	$prev?: Query

	swiper?: Swiper

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$container = $(this.el)
		this.$slides = this.$container.query('.swiper-slide')
		this.$pagination = this.$container.hasData('pagination') ? $<HTMLElement>(this.$container.getData('pagination')) : undefined
		this.$prev = this.$container.hasData('prev') ? $<HTMLElement>(this.$container.getData('prev')) : undefined
		this.$next = this.$container.hasData('next') ? $<HTMLElement>(this.$container.getData('next')) : undefined

		const options: SwiperOptions = {
			loop: this.$container.getData('loop') === 'true' || true,
			modules: [],
		}

		if (this.$next?.any && this.$prev?.any) {
			options.modules.push(Navigation)

			options.navigation = {
				nextEl: this.$next.el as HTMLElement,
				prevEl: this.$prev.el as HTMLElement,
			}
		}

		if (this.$pagination?.any) {
			options.modules.push(Pagination)

			options.pagination = {
				el: this.$pagination.el as HTMLElement,
				clickable: true,
			}
		}

		if (this.$slides.length > 1) {
			this.swiper = new Swiper(this.el, options)
		}
	}

	onDestroy() {
		this.swiper?.destroy()
		this.swiper = undefined
	}
}

export default new ComponentFactory({
	selector: '.slider',
	component: SliderComponent,
})
