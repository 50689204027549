import gsap from 'gsap'
import {SplitText} from 'gsap/SplitText'
import {$, Component, ComponentFactory, Query} from '../util'

gsap.registerPlugin(SplitText)

class TextAnimationComponent extends Component {
	$el: Query

	$chars: Query

	$words: Query

	$lines: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		const text = new SplitText(this.el, {
			charsClass: 'char',
			wordsClass: 'word',
			linesClass: 'line',
			type: 'words, chars',
		})

		this.$el = $(this.el)
		this.$chars = $(text.chars as HTMLElement[])
		this.$words = $(text.words as HTMLElement[])
		this.$lines = $(text.lines as HTMLElement[])

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return

					this._animate()
					observer.unobserve(entry.target)
					observer.disconnect()
				})
			},
			{
				rootMargin: '0px 0px 0px 0px',
			},
		)

		observer.observe(this.el)
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _animate() {
		const tl = gsap.timeline({paused: true, delay: 0})

		tl.set(this.$el, {
			autoAlpha: 1,
		})

		tl.from(this.$chars, {
			ease: 'power4.out',
			duration: 0.3,
			stagger: 0.025,
			opacity: 0,
			y: '30%',
			rotate: '10deg',
		})

		tl.play()
	}
}

export default new ComponentFactory({
	selector: '[data-text-animation]',
	component: TextAnimationComponent,
})
