import {$, Component, ComponentFactory, Query} from '../util'

class ModalToggleComponent extends Component {
	$toggle: Query

	modalId: string

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$toggle = $(this.el)
		this.modalId = this.$toggle.getData('toggle-modal')

		if (!this.modalId) {
			throw new Error('No id was given to the modal toggle')
		}

		this.$toggle.on('click', (e) => {
			e.preventDefault()
			this._toggleModal()
		})
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _toggleModal() {
		const $modal = $(this.modalId)

		if (!$modal.any) {
			throw new Error(`No modal was found with id: ${this.modalId}`)
		}

		$modal.emit('toggleModal')
	}
}

export default new ComponentFactory({
	selector: '[data-toggle-modal]',
	component: ModalToggleComponent,
})
