import {$, $PAGE_HEADER, Component, ComponentFactory, Query} from '../util'

const observer = new IntersectionObserver(
	(entries) => {
		const isIntersecting = entries.some((e) => e.isIntersecting)
		isIntersecting ? $PAGE_HEADER.addClass('header--light') : $PAGE_HEADER.removeClass('header--light')
	},
	{
		rootMargin: '0px 0px -100% 0px',
	},
)

class HeaderColorComponent extends Component {
	$el: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$el = $(this.el)
		observer.observe(this.el)
	}

	onDestroy(): void {
		observer.unobserve(this.el)
	}
}

export default new ComponentFactory({
	selector: '[data-dark-bg]',
	component: HeaderColorComponent,
})
