import {$, Component, ComponentFactory} from '../util'

const observer = new IntersectionObserver(
	(entries, observer) => {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) return

			const $el = $(entry.target as HTMLElement)
			const $img = $el.query('img.lazy__img')
			const $sources = $el.query('[data-srcset]')

			$img.on('load', () => $img.addClass('lazy__img--loaded'))

			$sources.forEach((src) => {
				src.setAttribute('srcset', src.getAttribute('data-srcset'))
				src.removeAttribute('data-srcset')
			})

			observer.unobserve(entry.target)
		})
	},
	{
		root: null,
		rootMargin: '0px',
	},
)

class LazyLoadComponent extends Component {
	onInit() {
		observer.observe(this.el)
	}

	onDestroy() {
		observer.unobserve(this.el)
	}
}

export default new ComponentFactory({
	selector: '.lazy',
	component: LazyLoadComponent,
})
