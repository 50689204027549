import {$, $PAGE_HEADER, Component, ComponentFactory, Query} from '../util'

class ContactToggleComponent extends Component {
	$toggle: Query

	$contact: Query

	// ----------------------------------------
	// Lifecycle Methods
	// ----------------------------------------

	onInit() {
		this.$toggle = $(this.el)
		this.$contact = $('#page-contact')

		this.$toggle.on('click', (e) => {
			e.preventDefault()
			this._toggleContact()

			if (this.$toggle.getData('close-menu')) {
				$PAGE_HEADER.emit('closeNav')
			}
		})
	}

	// ----------------------------------------
	// Private Methods
	// ----------------------------------------

	private _toggleContact() {
		this.$contact.emit('toggleContact')
	}
}

export default new ComponentFactory({
	selector: '[data-toggle-contact]',
	component: ContactToggleComponent,
})
